@import "~antd/dist/antd.css";
@import url("https://fonts.googleapis.com/css?family=Open+Sans");

.App {
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
}

.content {
  padding: 1rem;
}
.ant-menu-item {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}
.mb-20 {
  margin-bottom: 20px;
}
.w-full {
  width: 100%;
}
.w-med {
  width: 50%;
}
.w-95 {
  width: 98%;
}

.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.editor-container {
  /* height: 100%; */
  height: auto;
  width: 100%;
  border: 1px solid rgb(218, 218, 218);
  padding: 5px 10px 10px;
  border-radius: 10px;
}
.list-heading {
  width: 100%;
  height: 50px;
  background: white;
  /* justify-content: center; */
  padding-top: 16px;
  padding-left: 11px;
  border-radius: 10px;
}

.note-title {
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
  width: 300px;
}
.mt-10 {
  margin-top: 10px;
}
.ml-5 {
  margin-left: 15px;
}
.flex-row {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
